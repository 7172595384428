import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import Navbar from '../components/Navbar';
import AdminRoutes from '../components/admin/AdminRoutes';
import { auth, logOut } from '../firebase';

const { Content, Footer, Sider } = Layout;

const Panel = ({ history, location }) => {
  const [activeRoute, setActiveRoute] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(u => {
      if (!u) {
        history.push(`/`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  const salir = () => {
    logOut()
      .then(() => {
        history.push(`/`);
      })
      .catch(e => console.log(e));
  };

  return (
    <div>
      <Navbar />
      <Layout>
        <Sider breakpoint="lg" collapsedWidth="0">
          <div className="logo" />
          <Menu theme="dark" mode="inline" selectedKeys={[activeRoute]}>
            <Menu.Item key="/admin/questions">
              <Link to={`/admin/questions`}></Link>
              <Icon type="right-circle" />
              <span className="nav-text">Preguntas</span>
            </Menu.Item>
            <Menu.Item key="/admin/users">
              <Link to={`/admin/users`}></Link>
              <Icon type="right-circle" />
              <span className="nav-text">Resultados</span>
            </Menu.Item>
            <Menu.Item key="/admin/imagenes">
              <Link to={`/admin/imagenes`}></Link>
              <Icon type="right-circle" />
              <span className="nav-text">Imagenes</span>
            </Menu.Item>
            <Menu.Item key="/admin/secciones">
              <Link to={`/admin/secciones`}></Link>
              <Icon type="right-circle" />
              <span className="nav-text">Secciones</span>
            </Menu.Item>
            <Menu.Item key="/admin/formulario">
              <Link to={`/admin/formulario`}></Link>
              <Icon type="right-circle" />
              <span className="nav-text">Formulario</span>
            </Menu.Item>
            <Menu.Item key="exit">
              <Icon type="right-circle" />
              <span onClick={salir} className="nav-text">
                Salir
              </span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ margin: '24px 16px 0' }}>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
              <AdminRoutes />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Duty Free</Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default Panel;
