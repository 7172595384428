import React, { useEffect, useState } from 'react';
import '../styles/styles.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getUserById } from '../firebase';
import { storage } from '../storage';

const Gracias = ({ history }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userID = storage.getItem('userID');
    if (userID) {
      getUserById(userID)
        .then(u => setUser(u))
        .catch(e => console.log(e));
    } else {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const salir = () => {
    storage.removeItem('userID');
  };
  return (
    <div>
      <Navbar />
      <div className="thanks">
        <div className="thanksTitle">
          <p>
            <strong>¡Thanks for participating!</strong>
          </p>
          <br />
        </div>
        <div className="thanksResults">
          <p>
            Name: <strong>{user.name}</strong>
          </p>
          <p>
            Email: <strong>{user.email}</strong>
          </p>
          <p>
            Success:{' '}
            <strong>
              {user.rights} to {user.possible}
            </strong>
          </p>

          <br />

          <button onClick={salir} className="thanksResultsBotton">
            <a href="https://mexicocity.shopdutyfree.com/en/">
              Go to Duty Free Mexico City
            </a>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gracias;
