import React, { useContext } from 'react';
import '../styles/styles.css';
import { StateContext } from '../App';
import dufryCdmx from '../images/mexico_city_logo-rojo.png';

export default function Footer() {
  const { tema } = useContext(StateContext);
  return (
    <>
      <div className="footerMain">
        <div className="footerCenter">
          {tema.logo && (
            <img
              className="nflLogoF"
              src={tema.logo}
              alt="nfl-logo"
            />
          )}
          <img className="dufryLogo" src={dufryCdmx} alt="dufry" />
        </div>
      </div>
    </>
  );
}
