import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import Inicio from './pages/Inicio';
import Instrucciones from './pages/Instrucciones';
import Instructions from './pages/Instructions';
import Formulario from './pages/Formulario';
import UserForm from './pages/Form';
import Questions from './pages/TimedQuestions';
import Gracias from './pages/Gracias';
import Thankyou from './pages/Thankyou';
import { Route, Switch } from 'react-router-dom';
import LoginPage from './components/auth/LoginPage';
import Panel from './pages/Panel';
import { getActiveTheme, getSections, getFormFields } from './firebase';
import Tyces from './pages/Tyces';
import Tycen from './pages/Tycen';
import Privacidad from './pages/Privacidad';
import Privacy from './pages/Privacy';

const initialState = {
  sections: {},
  tema: {}
};

export const StateContext = createContext(initialState);

function App() {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    Promise.all([
      getActiveTheme('cdmx'),
      getSections(),
      getFormFields(),
    ])
      .then(([tema, sections, formFields]) => {
        if (tema && sections && formFields) {
          setState({
            tema,
            sections,
            form: formFields.data,
          });
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <StateContext.Provider value={state}>
      <Switch>
        <Route exact path={'/'} component={Inicio} />
        <Route exact path={'/instrucciones'} component={Instrucciones} />
        <Route exact path={'/instructions'} component={Instructions} />
        <Route exact path={'/formulario'} component={Formulario} />
        <Route exact path={'/form'} component={UserForm} />
        <Route exact path={'/questions'} component={Questions} />
        <Route exact path={'/preguntas'} component={Questions} />
        <Route exact path={'/gracias'} component={Gracias} />
        <Route exact path={'/thankyou'} component={Thankyou} />
        <Route exact path={'/es/terminos-y-condiciones'} component={Tyces} />
        <Route exact path={'/en/terms-and-conditions'} component={Tycen} />
        <Route exact path={'/es/politica-de-privacidad'} component={Privacidad} />
        <Route exact path={'/en/privacy-policy'} component={Privacy} />

        <Route path="/admin" component={Panel} />
        <Route path="/login" component={LoginPage} />
      </Switch>
    </StateContext.Provider>
  );
}

export default App;
