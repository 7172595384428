// Import required Firebase modules
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase app and services
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// Firestore collections
const questions = collection(db, 'questions');
const users = collection(db, 'users');
const themes = collection(db, 'themes');
const sections = collection(db, 'sections');
const form = collection(db, 'form');

// Firestore Functions

// Get sections
export const getSections = async () => {
  try {
    const snapshot = await getDocs(sections);
    const response = {};
    snapshot.forEach((doc) => {
      response[doc.id] = doc.data();
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Set sections
export const setSections = async (sectionsUpdate) => {
  const promises = Object.entries(sectionsUpdate).map(
    async ([key, value]) => {
      const docRef = doc(sections, key);
      return setDoc(docRef, value);
    }
  );
  return Promise.all(promises);
};

// Get form fields
export const getFormFields = async () => {
  try {
    const docRef = doc(form, 'fields'); // Create a document reference
    const snapshot = await getDoc(docRef); // Use getDoc for a single document
    if (snapshot.exists()) {
      return snapshot.data(); // Return the document's data
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching form fields:', error);
    throw error; // Throw error to be handled by caller if needed
  }
};

// Set form fields
export const setFormFields = async (fieldsUpdate) => {
  const docRef = doc(form, 'fields');
  return setDoc(docRef, { data: fieldsUpdate });
};

// Save question
export const saveQuestion = async (question) => {
  try {
    const questionDoc = question.id
      ? doc(questions, question.id)
      : doc(questions);
    question.id = questionDoc.id; // Set ID if not provided
    await setDoc(questionDoc, question);
    return question;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Get questions
export const getQuestions = async (language, site = 'cdmx') => {
  try {
    const queryConstraints = [
      where('site', '==', site),
      ...(language ? [where('language', '==', language)] : []),
    ];
    const questionQuery = query(questions, ...queryConstraints);
    const snapshot = await getDocs(questionQuery);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

// Delete question
export const deleteQuestion = async (id) => {
  try {
    const questionDoc = doc(questions, id);
    await deleteDoc(questionDoc);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Remove all questions in a collection
export const removeQuestionsCollection = async (site = 'cdmx') => {
  try {
    const batch = writeBatch(db);
    const questionQuery = query(questions, where('site', '==', site));
    const snapshot = await getDocs(questionQuery);
    snapshot.forEach((doc) => batch.delete(doc.ref));
    await batch.commit();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Upload questions collection
export const uploadQuestionsCollection = async (questionsJSON) => {
  try {
    const promises = questionsJSON.map((question) =>
      setDoc(doc(questions), question)
    );
    return Promise.all(promises);
  } catch (error) {
    console.error(error);
    return error;
  }
};

// User Functions

// Save user
export const saveUser = async (user) => {
  try {
    const userDoc = user.id ? doc(users, user.id) : doc(users);
    user.id = userDoc.id; // Set ID if not provided
    await setDoc(userDoc, user);
    return user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Get all users
export const getUsers = async () => {
  try {
    const snapshot = await getDocs(users);
    return snapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error(error);
    return [];
  }
};

// Get user by ticket
export const getUserByTicket = async (ticket) => {
  try {
    const userQuery = query(users, where('ticket', '==', ticket));
    const snapshot = await getDocs(userQuery);
    const user = snapshot.docs[0]?.data();
    return user || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Authentication Functions

// Log in
export const logIn = async (user) => {
  try {
    const result = await signInWithEmailAndPassword(
      auth,
      user.email,
      user.password
    );
    return result.user;
  } catch (error) {
    throw error;
  }
};

// Check if user is logged in
export const checkIfUser = (callback) => {
  return onAuthStateChanged(auth, callback);
};

// Log out
export const logOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error(error);
  }
};

// Storage Functions

// Upload file
export const uploadFile = async (folder, name, file) => {
  try {
    const fileRef = ref(storage, `${folder}/${name}`);
    const snapshot = await uploadBytes(fileRef, file);
    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Get the active theme for a specific site
export const getActiveTheme = async (site) => {
  try {
    const themeQuery = query(
      themes,
      where('active', '==', true),
      where('site', '==', site)
    );
    const snapshot = await getDocs(themeQuery);
    const activeTheme = snapshot.docs.map((doc) => doc.data())[0]; // Assuming one active theme per site
    return activeTheme || null;
  } catch (error) {
    console.error('Error fetching active theme:', error);
    return null;
  }
};

export const getUserByEmail = (email) => {
  return users
    .where('email', '==', email)
    .get()
    .then((s) => {
      const users = [];
      s.forEach((doc) => users.push(doc.data()));
      return users[0];
    })
    .catch((e) => console.log(e));
};

export const getUserById = async (id) => {
  try {
    // Create a query with the 'where' clause
    const userQuery = query(users, where('id', '==', id));
    // Execute the query
    const snapshot = await getDocs(userQuery);
    // Extract user data
    const user = snapshot.docs.map((doc) => doc.data())[0]; // Assuming a single match
    return user || null;
  } catch (error) {
    console.error('Error fetching user by ID:', error);
    throw error;
  }
};

export const saveTheme = async (theme, site) => {
  try {
    const docRef = doc(themes, theme.id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setDoc(docRef, theme);
      return snapshot.data();
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching form fields:', error);
    throw error;
  }
};

export const fire = { db, auth, storage };
