import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Upload, Button, Icon, Form, message } from 'antd';

import Loader from '../Loader';
import { fire, getActiveTheme, uploadFile, saveTheme } from '../../firebase';

const { Dragger } = Upload;

const AdminImages = ({ history }) => {
  const [site = 'cdmx'] = Object.values(useParams());
  const [theme, setTheme] = useState({});
  const [loading, setLoading] = useState(true);
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    fire.auth.onAuthStateChanged(u => {
      if (!u) {
        history.push(`/`);
      } else {
        setLoading(false);
        getUsedTheme(site);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsedTheme = (site) => {
    getActiveTheme(site)
      .then(theme => {
        if (theme) {
          setTheme(theme);
        }
      })
      .catch(e =>
        message.error('Something went wrong. Please try again later')
      );
  };

  const handleFile = (file, name) => {
    uploadFile(`${theme.title}/${site}` || 'themes', name || file.name, file)
      .then(link => {
        const newTheme = {
          ...theme,
          [name]: link,
          active: true
        };

        message.success(
          `Image uploaded successfully, don't forget to save your changes`
        );
        setPristine(false);
        setTheme(newTheme);
      })
      .catch(e =>
        message.error('Something went wrong. Please try again later')
      );
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!pristine) {
      saveTheme(theme, site)
        .then(response => {
          message.success(`Images configuration saved successfully`);
        })
        .catch(e =>
          message.error('Something went wrong. Please try again later')
        );
    }
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item
        style={{
          textAlign: 'center',
        }}
        label="Logo"
      >
        {theme.logo ? (
          <img
            style={{ width: 400, margin: '10px' }}
            src={theme.logo}
            alt="logo"
          />
        ) : (
          ''
        )}
        <Dragger
          accept=".jpg,.jpeg,.png,.git,.bmp"
          showUploadList={false}
          beforeUpload={(e) => handleFile(e, 'logo')}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Logo</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        style={{
          textAlign: 'center',
        }}
        label="Imágen de Registro (EN)"
      >
        {theme.registrationImageEnglish ? (
          <img
            style={{ width: 400, margin: '10px' }}
            src={theme.registrationImageEnglish}
            alt="registration"
          />
        ) : (
          ''
        )}
        <Dragger
          accept=".jpg,.jpeg,.png,.git,.bmp"
          showUploadList={false}
          beforeUpload={e => handleFile(e, 'registrationImageEnglish')}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Imágen de Registro (EN)</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        style={{
          textAlign: 'center'
        }}
        label="Imágen de Registro (ES)"
      >
        {theme.registrationImageSpanish ? (
          <img
            style={{ width: 400, margin: '10px' }}
            src={theme.registrationImageSpanish}
            alt="registration"
          />
        ) : (
          ''
        )}
        <Dragger
          accept=".jpg,.jpeg,.png,.git,.bmp"
          showUploadList={false}
          beforeUpload={(e) =>
            handleFile(e, 'registrationImageSpanish')
          }
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Imágen de Registro (ES)</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        style={{
          textAlign: 'center',
        }}
        label="Imágen del Cuestionario"
      >
        {theme.questionsImage ? (
          <img
            style={{ width: 400, margin: '10px' }}
            src={theme.questionsImage}
            alt="questions"
          />
        ) : (
          ''
        )}
        <Dragger
          accept=".jpg,.jpeg,.png,.git,.bmp"
          showUploadList={false}
          beforeUpload={(e) => handleFile(e, 'questionsImage')}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Imágen del Cuestionario</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        style={{
          textAlign: 'center',
        }}
        label="Imágen de Instrucciones"
      >
        {theme.instructionsImage ? (
          <img
            style={{ width: 400, margin: '10px' }}
            src={theme.instructionsImage}
            alt="instructions"
          />
        ) : (
          ''
        )}
        <Dragger
          accept=".jpg,.jpeg,.png,.git,.bmp"
          showUploadList={false}
          beforeUpload={(e) => handleFile(e, 'instructionsImage')}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Imágen de Instrucciones</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        style={{
          textAlign: 'center',
        }}
        label="Imágen de Idioma"
      >
        {theme.languageImage ? (
          <img
            style={{ width: 400, margin: '10px' }}
            src={theme.languageImage}
            alt="language"
          />
        ) : (
          ''
        )}
        <Dragger
          accept=".jpg,.jpeg,.png,.git,.bmp"
          showUploadList={false}
          beforeUpload={(e) => handleFile(e, 'languageImage')}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Imágen de Idioma</p>
        </Dragger>
      </Form.Item>

      <Button
        block
        type="primary"
        htmlType="submit"
        disabled={pristine}
      >
        Guardar
      </Button>
    </Form>
  );
};

export default AdminImages;
